<template>
	<div class="edit-form-container">
		<!-- :validate="" -->
		<el-form
			ref="itemForm"
			class="item-edit-form form-type-2"
			:model="formData"
			:rules="rules"
		>
			<div class="formRow relative">
				<div class="title section-title">Изображение</div>
				<ImgUploadBlock
					ref="ImgUploadBlock"
					:picture="itemData && itemData.picture_file_name"
					:replace-selected-file="true"
				/>
			</div>

			<div class="formRow mrow flex wrap">
				<div class="mcol-xs-6 mcol-sm-3 ">
					<el-form-item label="Баннер активен" prop="is_published">
						<el-switch
							v-model="formData.is_published"
							:active-value="1"
							:inactive-value="0"
						/>
					</el-form-item>
				</div>
			</div>
		</el-form>
	</div>
</template>

<script>
// import { mapActions, mapState } from 'vuex';
import 'element-ui/lib/theme-chalk/upload.css';

// import { required } from '@/constants/validation';
import { itemFormMixin } from '@/mixins';

export default {
	components: {
		ImgUploadBlock: () => import('../../components/form/ImgUploadBlock.vue')
	},
	mixins: [itemFormMixin],
	props: {
		itemData: {
			type: Object,
			default: () => ({})
		},
		fromAnotherInstance: Boolean
	},

	data() {
		return {
			itemId: null,

			formData: {
				is_published: 0
			}
		};
	},

	computed: {
		rules: () => ({
			// description_uk: required,
		})
	},

	methods: {}
};
</script>
